export default {
  data: function data() {
    return {
      up: "up",
      list: [{
        t: "热线支持",
        c: "服务人员通过电话向用户提供技术问题解答的过程。",
        image: require("@/assets/serve/after/1.png")
      }, {
        t: "远程维护",
        c: "安排技术人员为用户提供网络远程解决问题的过程。",
        image: require("@/assets/serve/after/2.png")
      }, {
        t: "产品升级",
        c: "定期官方发布的软件版本,优化体验。",
        image: require("@/assets/serve/after/3.png")
      }, {
        t: "功能改进",
        c: "根据客户需求对软件功能进行收集与分析进行统一产品完善。",
        image: require("@/assets/serve/after/4.png")
      }]
    };
  },
  computed: {
    imageStyle: function imageStyle() {
      var up = this.up;
      if (!this.$refs.image) return "";
      var width = this.$refs.image[0].offsetWidth;
      return up && {
        height: width * (672 / 810) + "px"
      };
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.up = new Date();
    $(window).on("resize", function () {
      _this.up = new Date();
    });
  },
  unmounted: function unmounted() {
    $(window).unbind();
  }
};