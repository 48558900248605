export default {
  data: function data() {
    return {
      list: [{
        t: "领先",
        icon: require("@/assets/serve/before/icon1.png")
      }, {
        t: "专业",
        icon: require("@/assets/serve/before/icon2.png")
      }, {
        t: "优先",
        icon: require("@/assets/serve/before/icon3.png")
      }, {
        t: "高效",
        icon: require("@/assets/serve/before/icon4.png")
      }]
    };
  }
};