export default {
  data: function data() {
    return {
      up: "up",
      list: [{
        t: "RFID 现场分析",
        c: "手持机无须布线，小巧方便，可随项目和人员而移动。相对视频识别信息系统，费用低廉，系统搭建速度快。",
        image: require("@/assets/serve/ing/1.png")
      }, {
        t: "RFID系统试点",
        c: "将客户自有的硬件子系统架设在生产环境的控制方案中，依据项目的RFID测试计划对系统进行测试，确定预想的系统解决方案。",
        image: require("@/assets/serve/ing/2.png")
      }, {
        t: "测试和集成",
        c: "为确保硬件作用和解决方案完整性而进行的质量保证和控制步骤。这一步骤包括产品配置，固件等级确认和硬件测试。",
        image: require("@/assets/serve/ing/3.png")
      }, {
        t: "RFID系统安装",
        c: "完整的网络布设和电源构架服务，将提供一站式的低风险安装。将RFID硬件方案在你的生产环境中实施，并进行配置。",
        image: require("@/assets/serve/ing/4.png")
      }, {
        t: "系统培训",
        c: "培训方式包括：一对一培训，培训讲师以及最终客户。将根据客户的需求定制培训内容、培训方式以及提供详尽培训文档。",
        image: require("@/assets/serve/ing/5.png")
      }, {
        t: "系统上线支持",
        c: "工程师会按照预先确定的时间，系统运行期间在现场协助你解决任何可能遇到的问题。",
        image: require("@/assets/serve/ing/6.png")
      }]
    };
  },
  computed: {
    imageStyle: function imageStyle() {
      var up = this.up;
      if (!this.$refs.image) return "";
      var width = this.$refs.image[0].offsetWidth;
      return up && {
        height: width * (672 / 900) + "px"
      };
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.up = new Date();
    $(window).on("resize", function () {
      _this.up = new Date();
    });
  },
  unmounted: function unmounted() {
    $(window).unbind();
  }
};