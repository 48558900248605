import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-33ed82e9"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "serve-nav"
};
var _hoisted_2 = {
  class: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(["item before", {
      current: $props.type === 'before'
    }]),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('btn', 'before');
    })
  }, " 售前服务 ", 2), _createElementVNode("div", {
    class: _normalizeClass(["item ing", {
      current: $props.type === 'ing'
    }]),
    onClick: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.$emit('btn', 'ing');
    })
  }, " 实施服务 ", 2), _createElementVNode("div", {
    class: _normalizeClass(["item after", {
      current: $props.type === 'after'
    }]),
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return _ctx.$emit('btn', 'after');
    })
  }, " 售后服务 ", 2)])]);
}