import "core-js/modules/es.array.slice.js";
export default {
  data: function data() {
    return {
      all: false,
      list: [// {
      //   q: "RFID电子标签是否可以对古文物进行管理？",
      //   a: "RFID电子标签可以对古文物进行管理。迷你抗金属标签尺寸微小，能效读取距离可达到数米，具有唯一性，粘贴在文物上即使之后撕下来也不损坏文物。使用RFID标签后，能够得知某个文物的具体存放位置；扫码读取标签，可以了解该文物的基本信息；文物借出给其他单位展出，如果标签被撕毁、或者归还时标签与借出时不一致，系统可以及时报警。",
      //   show: false,
      // },
      // {
      //   q: "RFID电子标签如何在养老院防止老人走丢？",
      //   a: "可以在老人的衣服上挂上RFID标签，在养老院大门上安装读取器，当老人走出大门时读取器读取到RFID电子标签的信息，系统可以及时发出报警。",
      //   show: false,
      // },
      // {
      //   q: "RFID技术怎样使消防设备管理可以更高效？",
      //   a: "运用 RFID 技术的消防器材固定资产管理系统，通过在消防器材上安装 RFID 电子标签，在后台绑定对应的资产信息，在关键节点和流程，通过对 RFID 电子标签信息的采集，来精确获取到资产的详细信息，比如位置、领用、归还情况等。从而实现管理器材的快速盘点以及出入库管理，提高效率的同时避免盘点漏缺情况的发生。",
      //   show: false,
      // },
      // {
      //   q: "RFID工具柜是怎么管理工具的？",
      //   a: "超高频RFID工器具智能管理柜，主要用于维修工具、测量仪器、仪表等器具的管理。RFID工器具智能管理柜内部和背部有阅读器天线，工器具上贴有RFID标签，通过无源RFID标签对工具进行唯一标识。工具领用或者归还时，通过APP软件进行权限的管理并开门，记录使用责任人，关门后柜体实现自动盘点，记录领用工具的数量及种类，在室内外的所有环境中都能发挥出色的性能。",
      //   show: false,
      // },
      // {
      //   q: "想要记录衣物的存储状况能不能用RFID技术？",
      //   a: "在衣物上可以使用RFID服装布质标签，利用RFID读取器就可以实现库存货品的快速盘点及高效交接，品类数目一目了然，实现库存的可视化管理。同时读取器可自动统计商品的销售、库存情况，并通过网络实时上传数据库系统，方便管理者实时获取销售、库存信息，及时进行商品配置，制定经营策略，管理更高效。",
      //   show: false,
      // },
      {
        q: "RFID电子标签是否可以对古文物进行管理？",
        a: "RFID电子标签可以对古文物进行管理。迷你抗金属标签尺寸微小，能效读取距离可达到数米，具有唯一性，粘贴在文物上即使之后撕下来也不损坏文物。使用RFID标签后，能够得知某个文物的具体存放位置；扫码读取标签，可以了解该文物的基本信息；文物借出给其他单位展出，如果标签被撕毁、或者归还时标签与借出时不一致，系统可以及时报警。",
        show: false
      }, {
        q: "RFID电子标签如何在养老院防止老人走丢？",
        a: "可以在老人的衣服上挂上RFID标签，在养老院大门上安装读取器，当老人走出大门时读取器读取到RFID电子标签的信息，系统可以及时发出报警。",
        show: false
      }, {
        q: "RFID技术怎样使消防设备管理可以更高效？",
        a: "运用 RFID 技术的消防器材固定资产管理系统，通过在消防器材上安装 RFID 电子标签，在后台绑定对应的资产信息，在关键节点和流程，通过对 RFID 电子标签信息的采集，来精确获取到资产的详细信息，比如位置、领用、归还情况等。从而实现管理器材的快速盘点以及出入库管理，提高效率的同时避免盘点漏缺情况的发生。",
        show: false
      }, {
        q: "RFID工具柜是怎么管理工具的？",
        a: "超高频RFID工器具智能管理柜，主要用于维修工具、测量仪器、仪表等器具的管理。RFID工器具智能管理柜内部和背部有阅读器天线，工器具上贴有RFID标签，通过无源RFID标签对工具进行唯一标识。工具领用或者归还时，通过APP软件进行权限的管理并开门，记录使用责任人，关门后柜体实现自动盘点，记录领用工具的数量及种类，在室内外的所有环境中都能发挥出色的性能。",
        show: false
      }, {
        q: "想要记录衣物的存储状况能不能用RFID技术？",
        a: "在衣物上可以使用RFID服装布质标签，利用RFID读取器就可以实现库存货品的快速盘点及高效交接，品类数目一目了然，实现库存的可视化管理。同时读取器可自动统计商品的销售、库存情况，并通过网络实时上传数据库系统，方便管理者实时获取销售、库存信息，及时进行商品配置，制定经营策略，管理更高效。",
        show: false
      }, {
        q: "如何鉴真溯源？",
        a: "打开手机NFC功能，或者打开专有APP，将手机轻轻靠近智能包装盒，即可快速鉴真防伪查询。如查询产品为真品，画面可见带有“验证成功”字样的众能防伪验证结果，并提供查询时间、地点，以及产品名称、生产厂商、产品批次、标签编号、出品时间、出品位置、出品地址等数据详情。",
        show: false
      }, {
        q: "防伪溯源标签信息会不会被篡改？",
        a: "每一个产品通过常达的智能包装，都有一个区块链标识编码，全球唯一，不可篡改，真实、权威、可靠。集中提供蚂蚁区块链接入服务，提供区块链记账存证，以保障溯源信息不可篡改。金融级安全可靠，具备海量数据支撑能力和性能，为真品保驾护航。通过北斗定位和区块链溯源追踪体系，从生产、运输、销售、消费等全程监控产品的各个环节，实现产品防伪、追溯、产品即时信息获取等功能，同时增强消费者的体验感及产品的附加值。",
        show: false
      }, {
        q: "想要用于管理车辆，应该选择什么标签？",
        a: "可以选择TAG-915-M13超高频RFID资产标签，适应于RFID车辆管理、户外RFID电力设备巡检管理、RFID电梯巡检管理、各种大型设备的跟踪和管理场合的特点。此产品带ABS 外壳，防护等级高，远距离读取，具备读距远，性价比高的优势。",
        show: false
      }, {
        q: "用什么胶把RFID标签固定在资产上的？",
        a: "可以按照客户的需求选择，主要使用的是3M胶。",
        show: false
      }]
    };
  },
  computed: {
    showList: function showList() {
      return this.all ? this.list : this.list.slice(0, 5);
    }
  }
};