import _objectSpread from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.string.fixed.js";
import top from "./components/top";
import serveNav from "./components/nav";
import serveBefore from "./components/serve-before";
import { mapMutations } from "vuex";
import serveIng from "@/views/serve/components/serve-ing";
import serveAfter from "@/views/serve/components/serve-after";
import serveQuestion from "@/views/serve/components/serve-question";
export default {
  // metaInfo: {
  //   title: "技术与服务_常达智能", // set a title
  //   meta: [
  //     {
  //       // set meta
  //       name: "keyWords",
  //       content: "售前服务,实施服务,售后服务,常达",
  //     },
  //     {
  //       name: "description",
  //       content:
  //         "我们始终凭借领先的产品、专业成熟的解决方案、专业的实施和客户优先的服务在行业享有很高知名度。公司致力于为客户提供专业的RFID技术方案咨询、物联网规划与管理咨询服务，帮助客户建立IT支撑的最佳管理体系，全面提升企业的核心竞争能力。我们多年专注于RFID技术方案在服装行业的研究、市场推广和实施服务，可以免费提供远程技术支援，提供技术咨询应答服务（节假日除外）；免费提供产品选型、应用方案、产品使用和维护等方面的咨询；免费为客户相关人员提供及时的当地技术培训。热线支持：服务人员通过电话向用户提供技术问题解答的过程。远程维护：安排技术人员为用户提供网络远程解决问题的过程。产品升级：定期官方发布的软件版本,优化体验。功能改进：根据客户需求对软件功能进行收集与分析进行统一产品完善。",
  //     },
  //   ],
  // },
  components: {
    serveQuestion: serveQuestion,
    serveIng: serveIng,
    serveBefore: serveBefore,
    top: top,
    serveNav: serveNav,
    serveAfter: serveAfter
  },
  methods: _objectSpread(_objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"])), {}, {
    btn: function btn($event) {
      this.$router.push({
        query: {
          type: $event
        }
      });
      this.navType = $event;
      this.scrollTo($event);
    },
    scrollTo: function scrollTo(ref) {
      // setTimeout(() => {
      // console.log($(`.serve-${ref}`));
      if ($(".serve-".concat(ref))) {
        var _top = $(".serve-".concat(ref)).offset() ? $(".serve-".concat(ref)).offset().top : 0;

        $("html,body").animate({
          scrollTop: _top
        }, 150);
      } // $("html,body").css({ scrollTop: $(`.serve-${ref}`).offset().top });
      // }, 100);

    }
  }),
  computed: {
    type: function type() {
      return this.$route.query.type;
    }
  },
  watch: {
    type: function type() {
      if (this.type) {
        this.scrollTo(this.type);
        this.navType = this.type;
      }
    }
  },
  data: function data() {
    return {
      fixed: false,
      navType: "before"
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.setHeaderColorWhite();
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
    this.scrollTo(this.type);
    $(window).on("scroll resize", function () {
      var scrollTop = document.documentElement.scrollTop | document.body.scrollTop;
      var domBoxTop = $("#serve-top").height();
      var y = scrollTop - domBoxTop;

      if (y > 1) {
        _this.fixed = true;
      } else {
        _this.fixed = false;
      }

      var ingTop = $(".serve-ing").offset().top;
      var after = $(".serve-after").offset().top;

      if (scrollTop > ingTop - 300 && scrollTop <= after - 300) {
        _this.navType = "ing";
      } else if (scrollTop > after - 300) {
        _this.navType = "after";
      } else {
        _this.navType = "before";
      }
    });
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
    $(window).unbind();
  }
};